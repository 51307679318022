'use strict';

angular.module('study.documents')
    .component('documentList', {
        templateUrl: 'modules/documents/components/list/template.html',
        controller: 'DocumentListController',
        bindings: {
            scope: '<',
            folder: '<',
        },
    });
