'use strict';

angular.module('study.documents')
.controller('DocumentsListItemController', ['$scope', 'StudyContext', function($scope, StudyContext) {
    var vm = this;

    // Elemento del árbol de documentos: puede ser un nodo de tipo fichero o "directorio"
    var node = $scope.node;

    // URL de descarga
    // TODO ver si vale la pena hacer una URL que no apunte a la API
    if (node.type !== 'file') {
        vm.fileUrl = '';
    } else {
        vm.fileUrl = 'api/documents/' + node.category + '/files/' + node.path;
    }

    // TODO Permisos sobre el fichero
    vm.canDownload = true;
}]);
