'use strict';

angular.module('study.documents')
    .config(['$stateProvider', 'RouteProvider', function($stateProvider, RouteProvider) {
        $stateProvider.state(RouteProvider.create('documents', {
            url: '/documents',
            component: 'documentsMain',
            permission: 'access study documents',
            redirectTo: function(transition) {
                var AccountManager = transition.injector().get('AccountManager');

                return AccountManager.init()
                    .then(function() {
                        var stateName;

                        if (AccountManager.hasPermission('read public documents')) {
                            stateName = 'documents.public';
                        } else if (AccountManager.hasPermission('read private documents')) {
                            stateName = 'documents.private';
                        }

                        if (stateName) {
                            return {
                                state: stateName,
                                params: transition.$to().params,
                            };
                        }
                    });
            },
        }));

        $stateProvider.state(RouteProvider.create('documents.public', {
            url: '/public{folder:any}',
            component: 'documentList',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Documentos públicos');
            }],
            permission: 'read public documents',
            resolve: {
                scope: function() {
                    return 'public';
                },
                folder: ['$transition$', function($transition$) {
                    return $transition$.params().folder || '';
                }],
            },
        }));

        $stateProvider.state(RouteProvider.create('documents.private', {
            url: '/private{folder:any}',
            component: 'documentList',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Documentos privados');
            }],
            permission: 'read private documents',
            resolve: {
                scope: function() {
                    return 'private';
                },
                folder: ['$transition$', function($transition$) {
                    return $transition$.params().folder || '';
                }],
            },
        }));
    }]);
