'use strict';

angular.module('study.documents')
    .controller('DocumentListController', ['ShareCrfSDK', 'ShareCrfApiErrorCodes', function(ShareCrfSDK, ShareCrfApiErrorCodes) {
        var vm = this;

        vm.ready = false;
        vm.loading = false;

        vm.error = false;
        vm.success = false;

        vm.currentFolder = '';
        vm.currentPath = [];
        vm.breadcrumbs = [];

        vm.$onInit = function() {
            // Initial folder
            vm.currentFolder = vm.folder || '';
            vm.currentPath = (vm.currentFolder ? vm.currentFolder.split('/') : []).filter(function(item) { return !!item; });

            // Set breadcrumbs
            var breadcrumbs = [];
            var completePath = [];
            for (var index in vm.currentPath) {
                var component = vm.currentPath[index];
                completePath.push(component);

                breadcrumbs.push({
                    name: component,
                    path: '/' + completePath.join('/'),
                });
            }
            vm.breadcrumbs = breadcrumbs;

            vm.ready = true;

            return vm.loadObjects();
        };

        // Load list

        vm.loadObjects = function() {
            vm.loading = true;
            vm.error = false;

            var params = {
                scope: vm.scope,
                folder: vm.currentFolder,
            };

            return ShareCrfSDK.documents.list(params)
                .then(function(documents) {
                    vm.loading = false;
                    vm.documents = documents;
                })
                .catch(function(error) {
                    vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);

                    throw error;
                });
        };

        // Download file

        vm.downloadUrl = function(file) {
            var params = {
                scope: vm.scope,
                file: (vm.currentFolder ? vm.currentFolder + '/' : '') + file.name,
                relative: true,
            };

            return ShareCrfSDK.documents.getDownloadUrl(params);
        };
    }]);
